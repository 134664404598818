import { Apiserver } from "./Apiserver";
import { ErrorMsg } from "./Constants";
import { UserSession } from "./UserSession";

export const PROD = "prod"
export const DEV = "dev"
export const DEMO = "demo"
export const QA = "qa"
export const TEST = "test"
export const LOCAL = "local"
export const OPTIONS = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }

export function log(msg) {
  if (Startup.debug) console.log(msg);
}
export class Startup {

  static debug = true;
  static PORTAL_URL = "";
  static ENV = LOCAL;
  static VERSION = "3.2"

  static init() {

    Startup.PORTAL_URL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/";

    if (Startup.PORTAL_URL == "https://portal.trustedroads.com/") {
      Apiserver.BASE_URL = "https://api.trustedroads.com/";
      Startup.debug = false;
      Startup.ENV = PROD;
    }
    else if (Startup.PORTAL_URL == "https://dev.portal.trustedroads.com/") {
      Apiserver.BASE_URL = "https://dev.api.trustedroads.com/";
      Startup.debug = true;
      Startup.ENV = DEV;
    }
    else if (Startup.PORTAL_URL == "https://demo.portal.trustedroads.com/") {
      Apiserver.BASE_URL = "https://demo.api.trustedroads.com/";
      Startup.debug = true;
      Startup.ENV = DEMO;
    }
    else if (Startup.PORTAL_URL == "https://qa.portal.trustedroads.com/") {
      Apiserver.BASE_URL = "https://qa.api.trustedroads.com/";
      Startup.debug = true;
      Startup.ENV = QA;
    }
    else if (Startup.PORTAL_URL == "https://test.portal.trustedroads.com/") {
      Apiserver.BASE_URL = "https://test.api.trustedroads.com/";
      Startup.debug = true;
      Startup.ENV = TEST;
    }
    else {
      Apiserver.BASE_URL = "https://dev.api.trustedroads.com/";
      Startup.debug = true;
      Startup.ENV = LOCAL;
    }
    UserSession.loadUserInfo();

    if (UserSession.isLoggedIn)
      console.log("LoggedIn=" + UserSession.account.identifier)
    console.log("VERSION=" + Startup.VERSION + " PORTAL_URL=" + Startup.PORTAL_URL + " API Server=" + Apiserver.BASE_URL)
  }
}

var months = ["Jan", "Feb", "March", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function getDateFromDict(date) {
  if (date == 0)
    return ""
  return (new Date(date / 1000)).toLocaleString("en-US", OPTIONS)
}

export function getDateFromDictWithNA(date) {
  if (date == 0)
    return "n/a"
  return (new Date(date / 1000)).toLocaleString("en-US", OPTIONS)
}

var DAYS_7 = 7 * 86400 * 1000000
export function dueAlert(duedatetime) {

  if (duedatetime == 0)
    return false

  let now = (new Date()).getTime() * 1000;
  if (duedatetime - now < DAYS_7)
    return true;

  return false;
}

export function setValue(id, value = "") {
  let node = document.getElementById(id);
  if (node) node.value = value;
}

export function getValue(id) {
  let node = document.getElementById(id);
  if (node) return node.value.trim();
  else return "";
}

export function addData(dict, id) {
  dict[id] = getValue(id);
}

export function addItem(array, id) {
  array.push({ id: getValue(id) });
}

export class Validator {
  static EMAIL_PATTERN = /\S+@\S+\.\S+/;

  static checkEmailId(errordict, id) {
    let value = getValue(id);
    if (value == "admin" || value == "demo")
      return true
    if (Validator.EMAIL_PATTERN.test(value)) return true;
    else errordict[id] = ErrorMsg.EMAIL_NOT_VALID;
    return false;
  }
  static checkValidOption(errordict, id) {
    let value = getValue(id);
    if (value != null && value.trim().length > 0) return true;
    else errordict[id] = ErrorMsg.OPTION_NOT_VALID;
    return false;
  }

  static checkValidString(errordict, id) {
    let value = getValue(id);
    if (value != null && value.trim().length > 0) return true;
    else errordict[id] = ErrorMsg.INPUT_CANNOT_BE_EMPTY;
    return false;
  }

  static checkValidUrl(errordict, id) {
    let value = getValue(id);
    if (value != null && value.trim().length > 0 &&
      (value.toLowerCase().startsWith('https://') || value.toLowerCase().startsWith('http://')))
      return true;
    else errordict[id] = ErrorMsg.URL_NOT_VALID;
    return false;
  }

  static checkValidPhone(errordict, id) {
    let value = getValue(id);
    if (value != null && value.trim().length > 0 && value.startsWith('+') && !isNaN(value.substring(1)))
      return true;
    else errordict[id] = ErrorMsg.PHONE_NOT_VALID;
    return false;
  }

  static dict_size(dict) {
    let c = 0;
    for (let item in dict) ++c;
    return c;
  }

  static checkResponseData(data, error) {
    delete error.backenderror;

    if (data["errorMessage"] && data["errorMessage"].length > 0) {
      error.backenderror = data["errorMessage"];
      return false;
    }
    if (data["errorfunction"] && data["errorfunction"].length > 0) {
      error.backenderror = "Unexpected Error, try gain later!";
      return false;
    }
    return true;
  }
}
