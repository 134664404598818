import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { log, Validator, getValue } from "../core/Util";
import { Apiserver } from "../core/Apiserver";
import { DBConstants } from "../core/Constants"
import { UserSession } from "../core/UserSession";
import { BannerBar } from "../core/MenuBar";


export class SearchInquiry extends CoreComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }
  async getdata(datain) {
    var datain = {}
    datain[DBConstants.PROGRAM_ID] = this.props.match.params.programid;
    this.showAnimation();
    let response = await Apiserver.postcall("get_program_simple", datain);
    this.closeAnimation();
    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.setState({ program: response.data })
    }
  }

  handleButtonClick = () => {

    let error = {};
    Validator.checkValidUrl(error, "websiteurl");

    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else
      this.postdata();
  };

  async postdata() {

    var datain = {};
    datain[DBConstants.WEBSITE_URL] = getValue("websiteurl");

    this.showAnimation();
    let response = await Apiserver.postcall(
      "search_website",
      datain
    );
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error })
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      let programid = response.data.programid
      this.redirectWithData(this.props, "/openinquiry/" + programid)
    }
  }

  render() {

    return (
      <div className="d-flex flex-row justify-content-center backimg pt20 pl10 pr10 pb20">
        <div className="container col-lg-5 col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw700">
          <div className="formborder pb20">

            <BannerBar />

            <div className="mt20 ml20 mr20">
              {this.getMessagePanel()}

              <label htmlFor="username" className="row p0 m0 mt25 propertytitle form-label">
                Open An Inquiry
              </label>

              <div className="mt10">
                <label className="form-label propertyname">Website:</label>
                {this.state.error.websiteurl && (
                  <div className="alert alert-danger">{this.state.error.websiteurl}</div>
                )}
                <input
                  type="website"
                  className="form-control"
                  id="websiteurl"
                  size="5"
                />
              </div>
              <div className="d-flex flex-row justify-content-center mt20 mb20">
                <button
                  onClick={this.handleButtonClick}
                  type="button"
                  className=" btn btn-primary" >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
