import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { UserSession } from "../core/UserSession";
import { Apiserver } from "../core/Apiserver";
import { log, Validator, getValue, setValue } from "../core/Util";
import { DBConstants, ErrorMsg } from "../core/Constants";


export class AddCorporate extends CoreComponent {

  constructor() {
    super();
    UserSession.setToAdmin()
  }

  handleButtonClick = () => {

    let error = {};
    Validator.checkValidString(error, "name");
    Validator.checkEmailId(error, "emailid");
    Validator.checkValidPhone(error, "phone");
    Validator.checkValidUrl(error, "websiteurl");
    Validator.checkValidUrl(error, "termlink");
    Validator.checkValidString(error, "dsrinfo");
    Validator.checkValidString(error, "street");
    Validator.checkValidString(error, "city");
    Validator.checkValidString(error, "zip");
    Validator.checkValidString(error, "country");


    if (Validator.dict_size(error) != 0) {
      this.setState({ error: error })
    } else
      this.postdata()
  };

  async postdata() {
    console.log("here")
    let datain = {}
    datain[DBConstants.CORPORATE_NAME] = getValue("name")
    datain[DBConstants.Email_ID] = getValue("emailid")
    datain[DBConstants.PHONE] = getValue("phone")
    datain[DBConstants.WEBSITE_URL] = getValue("websiteurl")
    datain[DBConstants.PROGARM_TERM_LINK] = getValue("termlink")
    datain[DBConstants.PROGRAM_DSR_INFO] = getValue("dsrinfo")
    datain[DBConstants.STREET] = getValue("street")
    datain[DBConstants.CITY] = getValue("city")
    datain[DBConstants.ZIP] = getValue("zip")
    datain[DBConstants.COUNTRY] = getValue("country")
    console.log(datain)
    this.showAnimation();
    let response = await Apiserver.postcall("register_corporate_account", datain);
    this.closeAnimation();

    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setValue("name")
      setValue("emailid")
      setValue("phone")
      setValue("websiteurl")
      setValue("termlink")
      setValue("dsrinfo")
      setValue("street")
      setValue("city")
      setValue("zip")
      setValue("country")
      this.setState({ error: error });
      this.redirectWithMessage(this.props, "/main/addcorporate", "Corporate Account Added!")
    }

  }

  render() {
    return (
      <div className="d-flex flex-row mt5 pl10 pr10 ">
        <div className="container col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw500">

          <div className="mt20 ml10 mr10">
            {this.getMessagePanel()}

            <label className="form-label">Corporate Name:</label>
            {this.state.error.name && (
              <div className="alert alert-danger mt10">{this.state.error.name}</div>
            )}
            <input className="form-control" id="name" size="5" />

            <label className="form-label mt10">Website:</label>
            {this.state.error.websiteurl && (
              <div className="alert alert-danger mt10">{this.state.error.websiteurl}</div>
            )}
            <input className="form-control" id="websiteurl" size="5" />

            <label className="form-label mt10">Email Id:</label>
            {this.state.error.emailid && (
              <div className="alert alert-danger mt10">{this.state.error.emailid}</div>
            )}
            <input className="form-control" id="emailid" size="5" />

            <label className="form-label  mt10">Phone:</label>
            {this.state.error.phone && (
              <div className="alert alert-danger mt10">{this.state.error.phone}</div>
            )}
            <input className="form-control" id="phone" size="5" />

            <label className="form-label mt10">Terms and Condition or Privacy Page Link:</label>
            {this.state.error.cemailid && (
              <div className="alert alert-danger mt10">{this.state.error.termlink}</div>
            )}
            <input className="form-control" id="termlink" size="5" />

            <label className="form-label mt10">Data Subject Access Request (DSAR) Link/Email:</label>
            {this.state.error.cemailid && (
              <div className="alert alert-danger mt10">{this.state.error.dsrinfo}</div>
            )}
            <input className="form-control" id="dsrinfo" size="5" />

            <label className="form-label">Street:</label>
            {this.state.error.street && (
              <div className="alert alert-danger mt10">{this.state.error.street}</div>
            )}
            <input className="form-control" id="street" size="5" />

            <label className="form-label mt10">City:</label>
            {this.state.error.city && (
              <div className="alert alert-danger mt10">{this.state.error.city}</div>
            )}
            <input className="form-control" id="city" size="5" />

            <label className="form-label mt10">Zip:</label>
            {this.state.error.zip && (
              <div className="alert alert-danger mt10">{this.state.error.zip}</div>
            )}
            <input className="form-control" id="zip" size="5" />

            <label className="form-label  mt10">Country:</label>
            {this.state.error.country && (
              <div className="alert alert-danger mt10">{this.state.error.country}</div>
            )}
            <input className="form-control" id="country" size="5" />

          </div>
          <div className="d-flex flex-row hc vc mt20 ml10 mr10">
            <button
              onClick={this.handleButtonClick}
              type="button"
              className=" btn btn-primary"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
}

