import React from "react";
import { CoreComponent } from "../core/CoreComponent";
import { log, Validator, getValue } from "../core/Util";
import { Apiserver } from "../core/Apiserver";
import { DBConstants } from "../core/Constants"
import { UserSession } from "../core/UserSession";
import { BannerBar } from "../core/MenuBar";


export class OpenInquiry extends CoreComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getdata();
  }
  async getdata(datain) {
    var datain = {}
    datain[DBConstants.PROGRAM_ID] = this.props.match.params.programid;
    this.showAnimation();
    let response = await Apiserver.postcall("get_program_simple", datain);
    this.closeAnimation();
    let error = {};
    if (!Validator.checkResponseData(response, error)) {
      this.setState({ error: error });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.setState({ program: response.data })
    }
  }

  handleButtonClick = () => {
    if (this.state.program.dsrtype == 1) {
      window.open('mailto:' + this.state.program.dsrinfo + '?subject=Access my data rights&body=');
    }
    else {
      window.open(this.state.program.dsrinfo, "_blank");
    }
  }

  render() {

    return (
      <div className="d-flex flex-row justify-content-center backimg pt20 pl10 pr10 pb20">
        <div className="container col-lg-5 col-md-6 col-s-12 fs15 m0 pl0 pr0 pt0 pb10 mw700">
          <div className="formborder pb20">

            <BannerBar />

            <div className="mt20 ml20 mr20">
              {this.getMessagePanel()}

              <label htmlFor="username" className="row p0 m0 mt25 propertytitle form-label">
                Open An Inquiry
              </label>

              <label className="form-label propertyvalue mt10">
                We require users to contact <span className="propertyname">{this.state.program && this.state.program.domain}</span> directly first to resolve your data rights.
              </label>

              <div className="row pt5">
                <div className="col-12"><span className="propertyname">Contact Details:</span></div>
                {(this.state.program && this.state.program.dsrtype == 1) ?
                  (<div className="col-12"><span className="propertyvalue">{this.state.program && this.state.program.dsrinfo}</span></div>)
                  : (<div className="col-12"><span className="propertyvalue">{this.state.program && this.state.program.dsrinfo}</span></div>)
                }
              </div>
            </div>

            <div className="d-flex flex-row justify-content-center mt20 mb20">
              <button
                onClick={this.handleButtonClick}
                type="button"
                className=" btn btn-primary" >
                Contact {this.state.program && this.state.program.corporatename}
              </button>
            </div>
            <div className="mt20 ml20 mr20">
              <label className="form-label propertyvalue mt10">
                If you have already contacted them and haven’t received a response within 10 days of submitting your inquiry,
                you may open a data rights inquiry with TrustedRoads.
                <div>
                  <a href={"../../inquiryform/" + (this.state.program && this.state.program.corporateid) + "/" + (this.state.program && this.state.program.programid)}>
                    Click here to start a new inquiry</a></div>
              </label>
            </div>

          </div>
        </div>
      </div>
    );

  }
}
