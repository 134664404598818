export class TextData {

  static USER_CONSENT_ACK_PART = "Your consent to collect personal data by <span class=\"fcmaroon\">{CORPORATE}</span> in accordance to their privacy programs is recorded and preserved.<br><br> Please sign-up or login using <span class=\"fcmaroon\">{USER_EMAIL_ID} </span> with <span class=\"fcmaroon\">trustedroads.com </span> to manage your consents"
  static CONSENT_ACK = "Opt-in acknowledgement completed !"
  static SUBMISSION_CONFIRMATION_PART =
    "Thank you for submitting an Inquiry for {WEBSITE_NAME}. <br><br> You should have received an email (send to <span class=\"fcmaroon\">{USER_EMAIL_ID}</span>) from us asking you to click a confirmation link , this action is needed to legitimate your submission. If the link is not clicked we won’t be able to validate or process your submission.<br><br> If you haven't received an email from us, please check your junk folder.";
  static USER_SUBMISSION_ACK_PART =
    "Your inquiry submission has been verified and validated, we should get it touch with you shortly to the provided email address : <span class=\"fcmaroon\">{USER_EMAIL_ID}</span>.<br>";
}

export class ErrorMsg {
  static EMAIL_NOT_VALID = "Please enter a valid Email id";
  static OPTION_NOT_VALID = "Please select a valid option";
  static INPUT_CANNOT_BE_EMPTY = "Please enter a valid value";
  static URL_NOT_VALID = "Please enter a valid website, example https://www.mydomain.com";
  static PHONE_NOT_VALID = "Please enter a valid phone with '+' like +1408765000";
  static NETWORK_ERROR = "Network error, please try again";
  static PWD_MIS_MATCH = "Passwords don't match";
  static BACKEND_ERROR = "Something went worng , please try again later"
}


export class DBConstants {

  static USER_EMAIL_ID = "useremailid"
  static Email_ID = "emailid"
  static Corporate_ID = "corporateid"
  static INQUIRY_ID = "inquiryid"
  static USER_ID = "userid"
  static REPLY_ID = "replyid"
  static SUPPORT_ID = "supportid"
  static CONSENT_ID = "consentid"
  static ENC_CONSENT_ID = "enc_consentid"
  static PROGRAM_ID = "programid"
  static ENV = "env"
  static ID = "id"
  static List = "list"
  static INQUIRY_TYPE = "type"
  static INQUIRY_RESIDENT_OF = "residentof"
  static INQUIRY_MORE_INFO = "moreinfo"

  static ACCOUNT_IDENTIFIER = "identifier"
  static CORPORATE_NAME = "corporatename"
  static PHONE = "phone"
  static BILLING_PHONE = "billingphone"
  static WEBSITE_URL = "websiteurl"
  static STREET = "street"
  static CITY = "city"
  static ZIP = "zip"
  static COUNTRY = "country"

  static DATE = "date"
  static YEAR = "year"
  static MONTH = "month"
  static DAY = "day"
  static HOUR = "hour"
  static MINUTE = "minute"
  static SECOND = "second"
  static DUE_DATE = "duedate"
  static DUE_YEAR = "dueyear"
  static DUE_MONTH = "duemonth"
  static DUE_DAY = "dueday"
  static DUE_HOUR = "duehour"
  static DUE_MINUTE = "dueminute"
  static DUE_SECOND = "duesecond"
  static LAST_ACT_DATE = "lastactdate"
  static LAST_ACT_YEAR = "lastactyear"
  static LAST_ACT_MONTH = "lastactmonth"
  static LAST_ACT_DAY = "lastactday"
  static LAST_ACT_HOUR = "lastacthour"
  static LAST_ACT_MINUTE = "lastactminute"
  static LAST_ACT_SECOND = "lastactsecond"

  static PWD_STATUS = "pwdstatus"
  static PWD = "pwd"
  static PWD_NOT_SET = "NOT SET"
  static PWD_SET = "SET"
  static DUMMY_PWD = "Empty"
  static ACCOUNT_TYPE = "accounttype"
  static OLD_PWD = "oldpwd"
  static NEW_PWD = "newpwd"
  static RETRIES = "retries"
  static FIRST_NAME = "fname"
  static LAST_NAME = "lname"


  static REPLY_TYPE = "replytype"
  static REPLY_FROM_USER = 0
  static REPLY_FROM_CORPORATE = 1

  static ACK = "ack"
  static ACK_NOT_COMPLETE = false
  static ACK_COMPLETE = true

  static STATUS = "status"
  static STATUS_REVOKED = "Revoked"
  static STATUS_REVOKED_BY_CORPOARTE = "Corporate Revoked"
  static STATUS_ACTIVE = "Active"

  static SIGNUP_LINK = 0
  static FORGOT_PWD_LINK = 1
  static SIGNUP_LINK_TYPE = "signuptype"
  static SIGN_UP_LINK_PARAMETER = "singuplinkparameter"

  static ACTIVE = "active"
  static ACCOUNT_ACTIVE = true
  static ACCOUNT_NOT_ACTIVE = false


  static MESSAGE = "message"
  static SUBJECT = "subject"
  static SUPPORT_MESSAGE = "supportmessage"
  static SUPPORT_SUBJECT = "supportsubject"


  static PROGARM_TERM_LINK = "termlink"
  static PROGARM_NAME = "programname"
  static PROGARM_DATA_COLLECTED = "datacollected"
  static PROGRAM_DSR_INFO = "dsrinfo"
  static PROGARM_DSR_INFO_TYPE = "dsrtype"
  static PROGARM_DSR_INFO_UNKNOWN = "0"
  static PROGARM_DSR_INFO_EMAIL = "1"
  static PROGARM_DSR_INFO_LINK = "2"


  static ACCOUNT_TYPE_CORPORATE = 1
  static ACCOUNT_TYPE_USER = 2
  static ACCOUNT_TYPE_ADMIN = 3
  static ACCOUNT_SUPER_ADMIN = 0
  static ADMIN_INDENTIFIER = "Admin"
  static DEMO_INDENTIFIER = "Demo"

  static MORE_INFO = "MoreInfo"
  static SIGN_UP_LINK_INCORRECT = "Sign up is incorrect"
  static ACCOUNT_ALREADY_SETUP = "Account is already setup, please login!"
  static PWD_NO_MATCH = "PWD_NO_MATCH"
  static IN_CORRECT_PWD = "IN_CORRECT_PWD"
  static DATA_INVALID = "DATA_INVALID"
  static ERROR = "ERROR"
  static NO_MATCHING_EMAIL = "NO_MATCHING_EMAIL"
  static TOKEN = "TOKEN"
  static INFO = "info"


  static FULL_PATH = "fullpath"
}
